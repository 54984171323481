import React from 'react';
import style from "./BlockWarning.module.css";

export default function BlockWarning(props){
	return(
		<div className={style.Warining}>
			<img src="/img/warning.svg" alt="Warning" />
			<div className={style.Wrap}>
				<span className={style.Title}>{props.data.title}</span>
				<span className={style.Message}>{props.data.message}</span>
			</div>
		</div>
	);
}
import React, { Component } from 'react';
import { Col, Row } from 'react-grid-system';
import Api, { canonicalUrl } from '../Api';
import BlockParser from '../components/BlockParser';
import SkeletonBox from '../skeletons/SkeletonBox';

export default class AboutPage extends Component {

	state = {
		data: null,
		fetched: false
	}

	componentDidMount(){
		canonicalUrl();
		document.title = "O mně" + Api.titleSuffix;
		window.scrollTo(0,0);
		this.fetchData();
	}

	fetchData(){
		Api.call('about')
		.then(response=>{
			if (response.success) {
				this.setState({
					fetched: true,
					data: response.data
				})
			} else {
				this.setState({
					fetched: true
				})
			}
		})
	}

	render(){

		if (!this.state.fetched || this.state.data === null)
			return(
				<div>
					<h1>O mně</h1>
					<Row>
						<Col xs={12} md={6} xl={9}>

							<SkeletonBox />
							<SkeletonBox />
							<SkeletonBox />
							<SkeletonBox />
							<SkeletonBox />
							<SkeletonBox width="75%" />

							<br />

							<SkeletonBox width="50%" height="2rem" />

							<SkeletonBox width="25%" />
							<SkeletonBox width="25%" />
							<SkeletonBox width="25%" />
							<SkeletonBox width="25%" />
							<SkeletonBox width="20%" />
						</Col>
						<Col xs={12} md={6} xl={3}>
							<SkeletonBox height="50%" />
							<SkeletonBox />
						</Col>
					</Row>
				</div>
			);


		return(
			<div>
				<h1>O mně</h1>
				<Row>
					<Col xs={12} md={6} xl={9}>
						<BlockParser data={this.state.data.about} />
						<br />
						<br />
					</Col>
					<Col xs={12} md={6} xl={3}>
						<img src={this.state.data.pfp ?? 'https://fakeimg.pl/600x600/'} alt="" />
						<span style={{textAlign: 'center',display: 'block',marginTop: '1.5rem'}}>{this.state.data.alt ?? ''}</span>
					</Col>
				</Row>
			</div>
		);
	}
}

import React, { Component } from 'react';
import BlockCode from './BlockParserComponents/BlockCode';
import BlockHeader from './BlockParserComponents/BlockHeader';
import BlockLinktool from './BlockParserComponents/BlockLinktool';
import BlockList from './BlockParserComponents/BlockList';
import BlockQuote from './BlockParserComponents/BlockQuote';
import BlockWarning from './BlockParserComponents/BlockWarning';
import BlockImage from './BlockParserComponents/BlockImage';
import sanitizeHtml from 'sanitize-html';
import { sanitizeParams } from '../Api';
import BlockTable from './BlockParserComponents/BlockTable';
import BlockEmbed from './BlockParserComponents/BlockEmbed';

export default class BlockParser extends Component{

	render(){
		return(
			<div>
				{
					(this.props.data.blocks ?? []).map((block,key)=>{
						switch(block.type){
							case 'header':
								return(<BlockHeader key={key} data={block.data} />);
							case 'paragraph':
								return(<p key={key} dangerouslySetInnerHTML={{__html: sanitizeHtml(block.data.text,sanitizeParams)}} />);
							case 'linkTool':
								return(<BlockLinktool key={key} data={block.data} />);
							case 'list':
								return(<BlockList key={key} data={block.data} />);
							case 'delimiter':
								return(<hr key={key} style={{border: 'none',borderBottom: '1px solid #cccccc'}} />);
							case 'warning':
								return(<BlockWarning key={key} data={block.data} />);
							case 'quote':
								return(<BlockQuote key={key} data={block.data} />);
							case 'code':
								return(<BlockCode key={key} data={block.data} />);
							case 'image':
								return(<BlockImage key={key} data={block.data} />);
							case 'table':
								return(<BlockTable key={key} data={block.data} />);
							case 'embed':
								return(<BlockEmbed key={key} data={block.data} />);
							default:
								return(<p key={key} style={{color: '#607d8b'}}>Unknown block "{block.type}"</p>);
						}
					})
				}
			</div>
		);
	}
}
import React from 'react';
import style from "./BlockQuote.module.css";

export default function BlockQuote(props){
	return(
		<div className={style.Quote}>
			<blockquote>
				{props.data.text}
			</blockquote>
			<span>{props.data.caption}</span>
		</div>
	);
}
import React, { Component } from 'react';

class MulticolorLogo extends Component {
	render() {
		return (
			<svg className={this.props.className} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 425.04 156.97">
			<defs>
				<style>.cls-1{'{fill:' + (this.props.color ?? '#eb5a68') +';}'}</style>
			</defs>
			<path className="cls-1" d="M16.9,0H33.36a0,0,0,0,1,0,0V16.5A16.45,16.45,0,0,1,16.9,33h0A16.45,16.45,0,0,1,.45,16.5v0A16.45,16.45,0,0,1,16.9,0Z" />
			<path className="cls-1" d="M41.73,0H58.51A16.5,16.5,0,0,1,75,16.55V91a0,0,0,0,1,0,0H58.23a16.5,16.5,0,0,1-16.5-16.5V0A0,0,0,0,1,41.73,0Z" transform="translate(116.74 91.02) rotate(-180)" />
			<path className="cls-1" d="M262.92,0h16.77a16.5,16.5,0,0,1,16.5,16.5V90.93a0,0,0,0,1,0,0H279.42a16.5,16.5,0,0,1-16.5-16.5V0A0,0,0,0,1,262.92,0Z" transform="translate(559.11 90.93) rotate(-180)" />
			<path className="cls-1" d="M304.23,0H321a16.5,16.5,0,0,1,16.5,16.5V90.93a0,0,0,0,1,0,0H320.73a16.5,16.5,0,0,1-16.5-16.5V0A0,0,0,0,1,304.23,0Z" transform="translate(641.73 90.93) rotate(-180)" />
			<path className="cls-1" d="M0,41.31H16.66A16.66,16.66,0,0,1,33.33,58V74.22A16.66,16.66,0,0,1,16.66,90.89h0A16.66,16.66,0,0,1,0,74.22V41.31a0,0,0,0,1,0,0Z" transform="translate(33.33 132.2) rotate(-180)" />
			<path className="cls-1" d="M192.28,0h16.45a0,0,0,0,1,0,0V16.5A16.45,16.45,0,0,1,192.28,33h0A16.45,16.45,0,0,1,175.83,16.5v0A16.45,16.45,0,0,1,192.28,0Z" />
			<path className="cls-1" d="M217.12,0h16.77a16.5,16.5,0,0,1,16.5,16.5V91a0,0,0,0,1,0,0H233.62a16.5,16.5,0,0,1-16.5-16.5V0A0,0,0,0,1,217.12,0Z" transform="translate(467.5 91.02) rotate(-180)" />
			<path className="cls-1" d="M175.38,41.31H192A16.66,16.66,0,0,1,208.71,58V74.22A16.66,16.66,0,0,1,192,90.89h0a16.66,16.66,0,0,1-16.66-16.66V41.31a0,0,0,0,1,0,0Z" transform="translate(384.09 132.2) rotate(-180)" />
			<path className="cls-1" d="M129.46,0h16.77a16.5,16.5,0,0,1,16.5,16.5V157a0,0,0,0,1,0,0H146a16.5,16.5,0,0,1-16.5-16.5V0A0,0,0,0,1,129.46,0Z" transform="translate(292.18 156.97) rotate(-180)" />
			<path className="cls-1" d="M88.09,0h16.83a16.5,16.5,0,0,1,16.5,16.5V90.89a0,0,0,0,1,0,0H104.6a16.5,16.5,0,0,1-16.5-16.5V0A0,0,0,0,1,88.09,0Z" transform="translate(209.52 90.89) rotate(-180)" />
			<path className="cls-1" d="M366.91.29h16.45a0,0,0,0,1,0,0V16.75A16.45,16.45,0,0,1,366.91,33.2h0a16.45,16.45,0,0,1-16.45-16.45v0A16.45,16.45,0,0,1,366.91.29Z" />
			<path className="cls-1" d="M391.76,0h16.77A16.5,16.5,0,0,1,425,16.55V91a0,0,0,0,1,0,0H408.26a16.5,16.5,0,0,1-16.5-16.5V0A0,0,0,0,1,391.76,0Z" transform="translate(816.8 91.02) rotate(-180)" />
			<path className="cls-1" d="M350.45,41.56h16.66a16.66,16.66,0,0,1,16.66,16.66V74.47a16.66,16.66,0,0,1-16.66,16.66h0a16.66,16.66,0,0,1-16.66-16.66V41.56a0,0,0,0,1,0,0Z" transform="translate(734.24 132.69) rotate(-180)" />
		</svg>
		);
	}
}

export default MulticolorLogo;

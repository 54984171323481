import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { sanitizeParams } from '../../Api';

export default function BlockList(props){
	return(
		<div>
			{
				props.data.style === "ordered" &&
				<ol>
					{
						props.data.items.map((item,key)=>(
							<li key={key} dangerouslySetInnerHTML={{__html: sanitizeHtml(item,sanitizeParams)}} />
						))
					}
				</ol>
			}
			{
				props.data.style === "unordered" &&
				<ul>
					{
						props.data.items.map((item,key)=>(
							<li key={key} dangerouslySetInnerHTML={{__html: sanitizeHtml(item,sanitizeParams)}} />
						))
					}
				</ul>
			}
		</div>
	);
}
import React, { Component } from 'react';
import { Link, NavLink } from 'react-router-dom';
import style from './HeaderMobile.module.css';
import MulticolorLogo from './MulticolorLogo';

class HeaderMobile extends Component {

	state = {
		opened: false,
		color: '#00000'
	}

	openClose = () => {
		document.body.style.overflow = this.state.opened ? 'unset' : 'hidden';
		this.setState({
			opened: !this.state.opened
		});
	}

	componentDidMount(){
		this.setRandomColor();
	}

	possibleColors = [
		'#FF6556',
		'#FFAC65',
		'#F9EA89',
		'#C765FF',
		'#65D6FF',
		'#C765FF'
	];
	setRandomColor = () => {
		
		var colors = this.possibleColors.filter(color => color !== this.state.color);
		
		this.setState({
			color: colors[Math.floor(Math.random() * colors.length)]
		})
	}

	render() {
		return (
			<div>
				<div style={{
					width: '100%',
					height: '1vh'
				}}></div>
				<div className={style.TopLine} style={{
					borderColor: this.state.color
				}}>
					<Link to="/">
						<MulticolorLogo className={style.Logo} color={this.state.color} />
					</Link>
					<div className={`${style.BurgerButton} ${this.state.opened ? style.Opened : ''}`} onClick={this.openClose}></div>
				</div>
				<div className={`${style.Nav} ${this.state.opened ? style.Opened : ''}`} onClick={this.openClose}>
					<NavLink exact to="/" className={style.NavLink} activeClassName={style.ActiveNavLink} style={{color:this.state.color}} onClick={this.setRandomColor} >Portfolio</NavLink>
					<NavLink to="/foto-video" className={style.NavLink} activeClassName={style.ActiveNavLink} style={{color:this.state.color}} onClick={this.setRandomColor} >Foto a Video</NavLink>
					{/*<NavLink to="/youtube" className={style.NavLink} activeClassName={style.ActiveNavLink}  style={{color:this.state.color}} onClick={this.setRandomColor} >YouTube</NavLink>*/}
					<NavLink to="/about" className={style.NavLink} activeClassName={style.ActiveNavLink}  style={{color:this.state.color}} onClick={this.setRandomColor} >O Mně</NavLink>
				
					<a className={style.NavLinkButton} style={{background:this.state.color}} href="mailto:ayana@ayanadesign.eu">Napište mi</a>
				</div>
			</div>
		);
	}
}

export default HeaderMobile;
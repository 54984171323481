import React, { Component } from 'react';
import style from './InteractiveImageSkeleton.module.css';
import skeletonStyle from './Skeleton.module.css';

export default class InteractiveImageSkeleton extends Component {
	
	render(){
		return(
			<div className={style.Wrapper}>
				<img className={style.Background} src="/img/16x9.png" alt="" />
				<div className={`${style.Overflow} ${skeletonStyle.Skeleton}`} style={{position: 'absolute'}} />
			</div>
		);
	}
}
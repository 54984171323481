import sanitizeHtml from 'sanitize-html';

export default class Api{

	static url = 'https://ayanadesign.eu/api/';


	static titleSuffix = ' | AYANA';

	static async call(path = '',data = {}, method = 'POST'){
		path = Api.url + path;

		var request = {
			payload: data
		}

		var response = await fetch(path, {
			headers: {
				'Content-Type': 'application/json'
			},
			method: method,
			cache: 'no-cache',
			body: JSON.stringify(request)
		});
		response = await response.json();
		return response;
	}
}

export function resizeImage(originalUrl, width, height) {
	return `${originalUrl}.resized.${width}x${height}${originalUrl.substring(originalUrl.lastIndexOf('.'))}`;
}

export const sanitizeParams = {
	allowedAttributes: {
		'*': [
			'class'
		],
		a: [
			'href',
			'name',
			'target'
		]
	},
	allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'a' ])
}

export function canonicalUrl(path = null) {
	if (!path) {
		document.querySelector('link[rel=canonical').href = document.location.href;
		return;
	}
	document.querySelector('link[rel=canonical').href = `${document.location.origin}/${path}`;
}

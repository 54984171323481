import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { sanitizeParams } from '../../Api';

export default function BlockHeader(props){
	switch (props.data.level){
		default:
		case 1:
			return(<h1 dangerouslySetInnerHTML={{__html: sanitizeHtml(props.data.text,sanitizeParams)}} />);
		case 2:
			return(<h2 dangerouslySetInnerHTML={{__html: sanitizeHtml(props.data.text,sanitizeParams)}} />);
		case 3:
			return(<h3 dangerouslySetInnerHTML={{__html: sanitizeHtml(props.data.text,sanitizeParams)}} />);
		case 4:
			return(<h4 dangerouslySetInnerHTML={{__html: sanitizeHtml(props.data.text,sanitizeParams)}} />);
		case 5:
			return(<h5 dangerouslySetInnerHTML={{__html: sanitizeHtml(props.data.text,sanitizeParams)}} />);
		case 6:
			return(<h6 dangerouslySetInnerHTML={{__html: sanitizeHtml(props.data.text,sanitizeParams)}} />);
	}
}
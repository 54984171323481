import React, { Component } from 'react';
import Api, { resizeImage } from '../Api';
import style from './InteractiveImage.module.css';

export default class InteractiveImage extends Component {

	state = {
		color: '#000000'
	}

	componentDidMount(){
		this.setRandomColor();
	}

	possibleColors = [
		'#FF6556',
		'#FFAC65',
		'#F9EA89',
		'#C765FF',
		'#65D6FF',
		'#C765FF'
	];
	setRandomColor() {
		this.setState({
			color: this.possibleColors[Math.floor(Math.random() * this.possibleColors.length)]
		})
	}

	render(){
		const cdnImageUrl = resizeImage(this.props.src, 854, 480);
		return(
			<div className={`${style.Wrapper} ${(this.props.interactive ?? true) ? style.Interactive: style.NonIteractive}`}>
				<img className={style.Background} src="/img/16x9.png" alt="" />
				<div className={style.Image}>
					<img src={this.props.bypassCDN === true ? this.props.src : cdnImageUrl} alt={this.props.title + Api.titleSuffix} />
				</div>
				<div className={style.Overflow} style={{
					background: this.props.color ?? this.state.color
				}} />
				<span className={style.Title}>{this.props.title ?? 'No title'}</span>
			</div>
		);
	}
}

import React, { Component } from 'react';
import Api, { canonicalUrl } from '../Api';
import ImageGallery from '../components/ImageGallery';

export default class PortfolioPage extends Component {

	state = {
		projectList: [],
		fetched: false
	}

	componentDidMount(){
		canonicalUrl();
		document.title = "Portfolio" + Api.titleSuffix;
		window.scrollTo(0,0);
		this.updateProjectList();
	}

	updateProjectList = () => {
		Api.call('portfolio/list')
		.then(response=>{
			if (response.success)
			{
				this.setState({
					projectList: response.data,
					fetched: true
				})
			}
		});
	}

	render(){
		return(
			<div>
				<h1>Portfolio</h1>

				<ImageGallery
					skeletonCount={6}
					skeleton={!this.state.fetched}
					images={this.state.projectList.map(project=>{
						return {
							id: project.ID,
							href: '/project/' + (project.slug?.length > 0 ? project.slug : project.ID),
							src: project.thumbnail,
							title: project.name
						}
					})}
				/>
			</div>
		);
	}
}

import React, { Component } from 'react';
import Api, { canonicalUrl } from '../Api';
import ImageGallery from '../components/ImageGallery';

export default class FotoVideoPage extends Component {

	state = {
		data: [],
		fetched: false
	}

	componentDidMount(){
		canonicalUrl();
		document.title = "Foto a video" + Api.titleSuffix;
		window.scrollTo(0,0);
		this.fetchData();
	}

	fetchData = () => {
		Api.call('fotovideo')
		.then(response=>{
			if (response.success) {
				this.setState({
					data: response.data,
					fetched: true
				})
			}
		})
	}

	render(){
		return(
			<div>
				<h1>Foto a video</h1>

				<ImageGallery
					skeleton={!this.state.fetched}
					skeletonCount={9}
					images={this.state.data.map(img=>{
						return {
							id: img.ID,
							href: (img.videoID === undefined || img.videoID === null || img.videoID.length < 1) ? img.image : 'https://youtu.be/' + img.videoID,
							src: img.image,
							title: ((img.videoID === undefined || img.videoID === null || img.videoID.length < 1) ? '' : '► ') + img.alt,
							actor: (img.videoID === undefined || img.videoID === null || img.videoID.length < 1) ? 'lightbox' : 'player'
						}
					})}
				/>
			</div>
		);
	}
}

import React, { Component } from 'react';
import skeletonStyle from './Skeleton.module.css';

class SkeletonBox extends Component {
	render() {
		return (
			<div className={skeletonStyle.Skeleton} style={{
				height: this.props.height ?? '1em',
				width: this.props.width ?? '100%',
				margin: this.props.margin ?? '0.5rem 0px'
			}}>
				
			</div>
		);
	}
}

export default SkeletonBox;
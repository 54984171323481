import React, { Component } from 'react';
import Api from '../Api';
import SkeletonBox from '../skeletons/SkeletonBox';

class RedirectPage extends Component {

	state = {
		fetched: false,
		redirect: null
	}

	componentDidMount(){
		window.scrollTo(0,0);
		this.fetchLink();
	}

	fetchLink = () => {
		Api.call('redirect',{
			link: this.props.match.params.link
		})
		.then(response=>{
			if (response.success){
				this.setState({
					fetched: true,
					redirect: response.data.link
				});
			} else {
				this.setState({
					fetched: true
				});
			}
		})
	}

	render() {

		if (!this.state.fetched)
			return (
				<div>
					<SkeletonBox height="2rem" width="50%" />
					<br />
					<SkeletonBox />
					<SkeletonBox width="75%" />
				</div>
			);
		if (this.state.redirect !== null){
			window.location.href = this.state.redirect;
			return(
				<div>
					<h1>Přesmerování</h1>
					<p>Vyčkejte prosím, než vás přemsěrujeme na požadovanou adresu.</p>
				</div>
			);
		}
		
		return(
			<div>
				<h1>Stránka nebyla nalezena</h1>
				<p>Omlouváme se, ale požadovaná stránka nebyla nalezena. Pravděpodobně byla zrušena, nebo jste špatne zadali její adresu.</p>
			</div>
		);
	}
}

export default RedirectPage;
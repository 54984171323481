import React, { Component } from "react";
import { Hidden, Visible } from "react-grid-system";
import { Link, NavLink } from "react-router-dom";
import style from './Header.module.css';
import HeaderMobile from "./HeaderMobile";
import MulticolorLogo from "./MulticolorLogo";

export default class Header extends Component {
	render(){
		return(
			<header className={style.Header}>
				<Hidden xs sm>
					<Link to="/" className={style.NavLink}>
						<MulticolorLogo className={style.Logo} />
					</Link>

				
					<div className={style.Nav}>
						<NavLink exact to="/" className={style.NavLink} activeClassName={style.ActiveNavLink}>Portfolio</NavLink>
						<NavLink to="/foto-video" className={style.NavLink} activeClassName={style.ActiveNavLink}>Foto a Video</NavLink>
						{/*<NavLink to="/youtube" className={style.NavLink} activeClassName={style.ActiveNavLink}>YouTube</NavLink>*/}
						<NavLink to="/about" className={style.NavLink} activeClassName={style.ActiveNavLink}>O Mně</NavLink>
					</div>
				</Hidden>
				<Visible xs sm>
					<HeaderMobile />
				</Visible>
			</header>
		);
	}
}
import MagicCursor from "./components/MagicCursor";
import PortfolioPage from "./pages/PortfolioPage";
import RedirectPage from "./pages/RedirectPage";

const { Container, setConfiguration } = require("react-grid-system");
const { BrowserRouter, Switch, Route } = require("react-router-dom");
const { default: Header } = require("./components/Header");
const { default: AboutPage } = require("./pages/AboutPage");
const { default: FotoVideoPage } = require("./pages/FotoVideoPage");
const { default: ProjectPage } = require("./pages/ProjectPage");

setConfiguration({
	containerWidths: [550, 750, 800, 1000, 1140]
})

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Container>
					<Header />

					<Switch>
						<Route exact path="/" component={PortfolioPage} />
						<Route path="/about" component={AboutPage} />
						<Route path="/foto-video" component={FotoVideoPage} />
						<Route path="/project/:projectID" component={ProjectPage} />
						<Route path="/p/:projectID" component={ProjectPage} />
						<Route path="/:link+" component={RedirectPage} />
					</Switch>
				</Container>
			</BrowserRouter>
			<MagicCursor />
		</div>
	);
}
export default App;

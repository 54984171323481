import React, { Component } from 'react';
import { SRLWrapper } from 'simple-react-lightbox';
import Api, { canonicalUrl } from '../Api';
import BlockParser from '../components/BlockParser';
import ImageGallery from '../components/ImageGallery';
import SkeletonBox from '../skeletons/SkeletonBox';

export default class ProjectPage extends Component {

	state = {
		project: null,
		fetched: false
	}

	componentDidMount(){
		canonicalUrl('project/' + this.props.match.params.projectID);

		document.title = "Projekt" + Api.titleSuffix;
		window.scrollTo(0,0);
		this.fetchProject();
	}

	fetchProject = () => {
		Api.call('portfolio/project',{
			id: this.props.match.params.projectID
		})
		.then(response=>{
			if (response.success){
				canonicalUrl('project/' + response.data.ID)
				document.title = response.data.name + Api.titleSuffix;
				this.setState({
					project: response.data,
					fetched: true
				});
			}
		})
	}

	render(){
		return(
			<div>
				{
					this.state.fetched &&
					<div>
						<h1>{this.state.project.name}</h1>

						<BlockParser data={this.state.project.description} />
					</div>
				}
				{
					!this.state.fetched &&
					<div>
						<SkeletonBox width="50%" height="2em" margin="0.67em 0px 3em 0px" />

						<SkeletonBox />
						<SkeletonBox />
						<SkeletonBox />
						<SkeletonBox />
						<SkeletonBox width="75%" />
					</div>
				}

				<SRLWrapper>
					<ImageGallery
						skeleton={!this.state.fetched}
						skeletonCount={2}
						maxCols={2}
						interactive={false}
						images={((this.state.project ?? {}).images ?? []).map((img,key)=>{
							return {
								id: key,
								src: img
							}
						})}
					/>
				 </SRLWrapper>
			</div>
		);
	}
}

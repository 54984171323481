import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Lightbox from './Lightbox';

class ActionActor extends Component {

	state = {
		opened: false
	}

	toggleOpen = () => {
		this.setState({
			opened: !this.state.opened
		})
	}

	render() {
		switch(this.props.type)
		{
			case 'lightbox':
				return(

					<div>
						<div onClick={this.props.open}>
							{this.props.children}
						</div>
						{
							this.props.opened &&
							<Lightbox close={this.props.close} prev={this.props.canPrev ? this.props.prev : null} next={this.props.canNext ? this.props.next : null} src={this.props.href} alt={this.props.href + " | Ayana"} anim={this.props.anim} />
						}
						{/* Preload image */}
						<div style={{
							width: '1px',
							height: '1px',
							position: 'absolute',
							top: 0,
							left: 0,
							opacity: 0,
							background: `url('${this.props.href}')`,
						}} />
					</div>
				)
			case 'player':
			case 'external':
				return(
					<a href={this.props.href} target="_blank" rel="noreferrer">
						{this.props.children}
					</a>
				);
			case 'link':
			default:
				return (
					<Link to={this.props.href} style={{cursor: 'default'}}>
						{this.props.children}
					</Link>
				);
		}
	}
}

export default ActionActor;

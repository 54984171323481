import React from 'react';
import style from './BlockLinktool.module.css';

export default function BlockLinktool(props){
	return(
		<a href={props.data.link} className={style.Wrap} target="_blank" rel="nofollow noindex noreferrer">
			<div className={style.Image} style={{
				backgroundImage: 'url(' + props.data.meta.image.url +')'
			}} />
			<div className={style.Title}>
				{props.data.meta.title ?? props.data.link}
			</div>
			<p className={style.Description}>
				{props.data.meta.description ?? ''}
			</p>
			<span className={style.Anchor}>{props.data.link}</span>
		</a>
	);
}
import React, { Component } from "react";
import './MagicCursor.css';

export default class MagicCursor extends Component {

	state = {
		x: -100,
		y: 0,

		rotation: {
			x: 0,
			y: 0,
			angle: 0,
		},

		scale: 1,

		visible: true,
	}

	cursorTravel = 0;
	cursorTravelInterval = -1;

	componentWillUnmount() {
		clearInterval(this.cursorTravelInterval);
	};

	prevEvent = null;
	currentEvent = null;
	speed = 0;
	cursorSpeedCheckTime = 50;
	idle = 0;
	updateScale = () => {
		if (this.prevEvent && this.currentEvent) {
			const movementX = Math.abs(this.currentEvent.screenX - this.prevEvent.screenX);
			const movementY = Math.abs(this.currentEvent.screenY - this.prevEvent.screenY);
			const movement = Math.sqrt(movementX * movementX + movementY * movementY);

			const speed = this.cursorSpeedCheckTime / 10 * movement;
			const rawScale = 1 + (speed / 900);
			const normalizedScale = rawScale < 1 ? 1 : rawScale > 2.5 ? 2.5 : rawScale;

			this.setState({
				scale: normalizedScale,
			});
		}

		this.prevEvent = this.currentEvent;
	}

	componentDidMount() {
		this.cursorTravelInterval = setInterval(this.updateScale, this.cursorSpeedCheckTime);

		document.addEventListener('mouseleave', () => {
			this.setState({
				visible: false,
			})
		});
		document.addEventListener('mouseenter', () => {
			this.setState({
				visible: true,
			});
		});

		document.addEventListener('mousemove', (e) => {
			this.currentEvent = e;

			let newRotation = this.state.rotation;

			const diffY = e.clientY - this.state.rotation.y;
			const diffX = e.clientX - this.state.rotation.x;
			const minimalAngleDistance = 10;

			if (diffX * diffX + diffY * diffY > minimalAngleDistance * minimalAngleDistance) {
				const angle = Math.atan2(diffY, diffX)* 180 / Math.PI;
				newRotation = {
					x: e.clientX,
					y: e.clientY,
					angle: angle,
				}
			}

			this.cursorTravel += Math.max( Math.abs(diffX), Math.abs(diffY));

			this.setState({
				x: e.clientX,
				y: e.clientY,
				rotation: newRotation,
				visible: true,
			})
		});

		document.addEventListener('mouseover', e => {
			if(this.elementIsHoverable(e.target)) {
				document.body.classList.add('cursor-hover');
			} else {
				document.body.classList.remove('cursor-hover');
			}
		});
	}

	elementIsHoverable(element) {
		const hoverableTypes = [
			'A'
		];

		if (element.classList?.contains('hoverable')) {
			return true;
		}

		return hoverableTypes.includes(element.tagName);
	}

	render() {
		return(
			<div className="cursor__wrap">
				<div className="cursor" style={{
					transform: `translate3d(calc(${this.state.x}px - 50%), calc(${this.state.y}px - 50%), 0px) rotate(${this.state.rotation.angle}deg) scaleX(${this.state.scale}) translateX(-${(this.state.scale - 1)}rem)`,
				}}></div>
				<div className="cursor-dot" style={{
					transform: `translate3d(calc(${this.state.x}px - 50%), calc(${this.state.y}px - 50%), 0px)`,
				}}></div>
			</div>
		);
	}
}

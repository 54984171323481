import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { railscasts } from 'react-syntax-highlighter/dist/esm/styles/hljs';

export default function BlockCode(props){
	return(
		<SyntaxHighlighter style={railscasts} customStyle={{padding: '1rem',borderRadius: '5px'}}>
			{props.data.code}
		</SyntaxHighlighter>		
	);
}
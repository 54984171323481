import React from 'react';

export default function BlockEmbed(props){
	return(
		<div style={{
			marginTop: '10px',
			marginBottom: '10px'
		}}>
			<iframe 
				width={props.data.width}
				height={props.data.height}
				src={props.data.embed}
				referrerPolicy="no-referrer"
				title={'Embed from ' + props.data.service + ': ' + props.data.caption}
				loading="lazy"
				allowpaymentrequest={false}
				style={{
					border: '1px solid #eaeaea',
					borderRadius: '5px',
					display: 'block',
					margin: 'auto',
					maxWidth: '100%'
				}}
			/>
			<span style={{
				display: 'block',
				margin: 'auto',
				width: props.data.width + 'px',
				fontSize: '0.7em',
				color: '#607d8b',
				paddingTop: '5px',
				paddingBottom: '5px',
				maxWidth: '100%'
			}} >
				Source:&nbsp;
				<a href={props.data.source} target="_blank" rel="noreferrer" style={{
					color: '#607d8b'
				}}>
					{props.data.source}
				</a>
			</span>
		</div>
	);
}
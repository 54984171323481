import React, { Component } from 'react';
import style from './Lightbox.module.css';

class Lightbox extends Component {

	componentDidMount(){
		this.registerKeyListen();
	}

	componentWillUnmount(){
		this.unregisterKeyListen();
	}

	registerKeyListen = () => {
		window.addEventListener('keydown',this.onKeyDown);
	}

	unregisterKeyListen = () => {
		window.removeEventListener('keydown',this.onKeyDown);
	}

	onKeyDown = e => {

		/*
			Left key - 37
			Right key - 39
			Escape - 27
		*/

		if (e.keyCode === 37 && this.props.prev !== null)
			this.props.prev();

		if (e.keyCode === 39 && this.props.next !== null)
			this.props.next();

		if (e.keyCode === 27)
			this.props.close();
	}

	render() {
		return (
			<div className={`${style.Lightbox} ${this.props.anim ? style.anim : ''}`}>
				<img className={`${style.Image} ${this.props.anim ? style.anim : ''}`} src={this.props.src} alt={this.props.alt + ' | Ayana'} />
				<div className={style.Protection} onClick={this.props.close}></div>
				<span className={`${style.Close} hoverable`} onClick={this.props.close}>×</span>

				{
					this.props.prev !== null &&
					<img onClick={this.props.prev} className={`${style.Prev} hoverable`} src="/img/sipicka.svg" alt="" draggable={false} />
				}
				{
					this.props.next !== null &&
					<img onClick={this.props.next} className={`${style.Next} hoverable`} src="/img/sipicka.svg" alt="" draggable={false} />
				}
			</div>
		);
	}
}

export default Lightbox;

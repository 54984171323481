import React from 'react';
import sanitizeHtml from 'sanitize-html';
import { sanitizeParams } from '../../Api';
import style from './BlockTable.module.css';

export default function BlockTable(props){
	return(
		<table className={style.Table}>
			<tbody>
				{
					props.data.content.map((row,rowKey)=>(
						<tr key={rowKey}>
							{
								row.map((col,colKey)=>(
									<td key={colKey} dangerouslySetInnerHTML={{__html: sanitizeHtml(col,sanitizeParams) }} />
								))
							}
						</tr>
					))
				}
			</tbody>
		</table>
	);
}
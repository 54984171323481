import React from 'react';

export default function BlockImage(props){
	return(
		<div style={{
			marginTop: '10px',
			marginBottom: '10px',
			border: props.data.withBorder === true ? '1px solid #eaeaea' : '0px solid transparent',
			background: props.data.withBackground === true ? '#f6f7f8' : 'transparent',
			borderRadius: '5px',
			boxSizing: 'border-box',
			overflow: 'hidden',
		}}>
			<img
				src={props.data.file.url}
				alt={props.data.caption}
				title={props.data.caption}
				style={{
					maxWidth: '100%',
					width: props.data.stretched === true ? '100%' : 'auto',
					padding: props.data.withBackground === true ? '15px' : '0px',
					display: 'block',
					margin: props.data.withBackground === true ? 'auto' : 'initial'
				}}
			/>
		</div>
	);
}
import React, { Component } from 'react';
import { Col, Row } from 'react-grid-system';
import InteractiveImageSkeleton from '../skeletons/InteractiveImageSkeleton';
import ActionActor from './ActionActor';
import InteractiveImage from './InteractiveImage';

export default class ImageGallery extends Component {

	state = {
		lightboxIndex: null
	}

	render(){
		return(
			<div style={{
				paddingLeft: '5px',
				paddingRight: '5px',
			}}>
				<Row>
					{
						this.props.skeleton &&
						this.getDummyArray(this.props.skeletonCount ?? 6).map((id)=>(
							<Col xs={12} md={6} xl={12/(this.props.maxCols ?? 3)} key={id} style={{
								padding: '10px',
								overflow: 'hidden'
							}}>
								<InteractiveImageSkeleton />
							</Col>
						))
					}
					{
						!this.props.skeleton &&
						this.props.images.map((image,index)=>{

							return(
							<Col xs={12} md={6} xl={12/(this.props.maxCols ?? 3)} key={image.id} style={{
								padding: '10px',
								overflow: 'hidden'
							}}>
								<ActionActor href={image.href} type={image.actor} anim={this.state.anim} opened={index === this.state.lightboxIndex} open={()=>{this.openLightbox(index)}} close={()=>{this.openLightbox(null)}} prev={()=>{this.prevImg(index)}} next={()=>{this.nextImg(index)}} canPrev={this.getPrevImg(index) !== null} canNext={this.getNextImg(index) !== null} >
									<InteractiveImage bypassCDN={this.props.bypassCDN} src={image.src} title={image.title} interactive={this.props.interactive ?? true} />
								</ActionActor>
							</Col>
							);
						})
					}
				</Row>
			</div>
		);
	}


	getDummyArray = count => {
		var rtn = [];

		for (var i = 0; i < count; i++)
		{
			rtn.push(-1-i);
		}

		return rtn;
	}

	prevImg = index => {
		var prev = this.getPrevImg(index);
		if (prev !== null)
			this.setState({
				lightboxIndex: prev,
				anim: false
			});
	}

	getPrevImg = index => {

		for (let i = index - 1; i >= 0; i--) {
			if (this.props.images[i].actor === 'lightbox')
			{
				return i;
			}
		}

		return null;
	}

	nextImg = index => {

		var next = this.getNextImg(index);

		if (next !== null)
			this.setState({
				lightboxIndex: next,
				anim: false
			});
	}

	getNextImg = index => {

		for (let i = index + 1; i < this.props.images.length; i++) {
			if (this.props.images[i].actor === 'lightbox')
			{
				return i;
			}
		}

		return null;
	}

	openLightbox = index => {
		this.setState({
			lightboxIndex: index,
			anim: true
		});
	}
}
